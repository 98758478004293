
<template>
  <loginscreen />
</template>

<script>
import loginscreen from "@/components/login/loginscreen";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    loginscreen,
  },
  metaInfo: {
    title: "Welkom bij Flex vastgoed CRM | Inloggen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>