import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";

export const userLogin = async function(email, password) {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
    const token = await firebase.auth().currentUser.getIdToken(true);
    if (token) axios.defaults.headers.common["fireToken"] = token;
    await axios.get(`http://localhost:3000/api/rest/v2/whoami`);
   // this.$router.push("/Dashboard");
  } catch (error) {
    this.$swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Gebruikersnaam of wachtwoord is onjuist.!",
      footer:
        '<a href="mailto:info@flexvastgoedcrm.nl?subject=Inloggen%20lukt%20niet%20op%20de%20website">Hulp nodig?</a>',
    });
  }
};

export const microsoftLogin = async function() {
  const providor = new firebase.auth.OAuthProvider("microsoft.com");
  await firebase.auth().signInWithPopup(providor);
};


export const resetPassword = async function(email) {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    this.$swal.fire({
      position: "top-end",
      icon: "success",
      title: "Wachtwoord link verzonden",
      showConfirmButton: false,
      timer: 1500,
    });
  } catch (error) {
    this.$swal.fire("Bericht is verzonden");
  }
};

export const signOut = async function() {
  try {
    await firebase.auth().signOut();
    this.$router.push("/");
  } catch (error) {
    console.error("Fout bij uitloggen:", error);
  }
};
